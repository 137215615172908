<script setup lang="ts">
import * as yup from 'yup'
import { useToast } from 'vue-toastification'
import type { AuthView } from '@base/types'

const emit = defineEmits<{
  (e: 'changeView', view: AuthView): void
  (e: 'close'): void
}>()

const toast = useToast()
const { $t, $switchRoute } = useI18n()
const { registerWithPassword } = useAuth()
const { emailValidation, passwordValidation, companyIdNumberValidation } = useValidations()
const { platform } = useAppConfig()

const validationSchema = yup.object().shape({
  name: yup.string().required(String($t('components.form.nameRequired'))).min(5, String($t('components.form.nameMinLength'))),
  email: emailValidation,
  password: passwordValidation,
  consent: yup.boolean().required().oneOf([true], String($t('components.form.consentRequired'))),
  isExchangeOfficeOwner: yup.boolean(),
  companyIdNumber: companyIdNumberValidation,
  companyName: yup.string().required(String($t('components.form.companyNameRequired'))).min(6, String($t('components.form.companyNameMinLength'))),
  url: yup.string().url(String($t('components.form.validUrl'))),
})

const { meta, isSubmitting, handleSubmit, values, setFieldValue } = useForm({ validationSchema })

const onSubmit = handleSubmit(async values => {
  const error = await registerWithPassword({
    email: values.email,
    password: values.password,
  },
  {
    name: values.name,
    isExchangeOfficeOwner: true,
    companyIdNumber: values.companyIdNumber,
    companyName: values.companyName,
    url: values.url,
    platformId: platform.id,
    invitedByUserId: useCookie('bitbeli-invitedByUserId').value,
  })

  if (error)
    return toast.error(String($t('components.form.emailAlreadyExists')))

  emit('close')
  $switchRoute('/signup-success')
})

const addHttpsPrefix = (value: string) => {
  if (value && (!value.includes('https'))) {
    const newValue = `https://${value}`
    const urlPattern = /^(https?:\/\/)?([\da-z.-]+)\.([a-z.]{2,6})([/\w .-]*)*\/?$/
    if (urlPattern.test(newValue)) {
      setFieldValue('url', newValue)
    }
  }
}
</script>

<template>
  <h4 :data-test="getCypressId('sign-up-header')" heading4 font-800 my-16px>
    {{ $t('components.form.createNewAccount') }}
  </h4>
  <div>
    <div
      pb-0px flex-wrap flex
      gap-4px
      fubex-rounded-md text-left
      font-400 paragraph-sm text-neutral-900
      w-full justify-start items-start
      rounded-b-0 mb-16px
      border-b-3px border-primary-500 dark:border-primary-300
    />
    <form @submit="onSubmit">
      <div v-auto-animate flex flex-col gap-16px>
        <FInputVee name="companyName" cypress-prefix="signup" type="text" :label="String($t('components.form.exchangeOffice'))" :placeholder="String($t('components.form.enterExchangeOfficeName'))" />
        <FInputVee name="companyIdNumber" cypress-prefix="signup" type="text" :label="String($t('components.form.companyIdNumber'))" :placeholder="String($t('components.form.enterCompanyIdNumber'))" />
        <FInputVee name="name" cypress-prefix="signup" type="text" :label="String($t('components.form.manager'))" :placeholder="String($t('components.form.enterNameAndSurname'))" />
        <FInputVee name="url" type="text" :label="String($t('components.form.website'))" :placeholder="String($t('components.form.enterWebsiteUrl'))" @blur="addHttpsPrefix(values.url)" />
        <FInputVee name="email" type="text" :label="String($t('components.form.emailAddress'))" :placeholder="String($t('components.form.enterEmail'))" />
        <FInputVee name="password" type="password" :label="String($t('components.form.password'))" :placeholder="String($t('components.form.enterPassword'))" />
      </div>
      <div mt-40px flex>
        <FCheckboxVee name="consent">
          {{ $t('components.form.consentText') }}
          <NuxtLink :to="`${platform.domain}/docs/vop.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            {{ $t('components.form.termsAndConditions') }}
          </NuxtLink>
          {{ $t('components.form.and') }}
          <NuxtLink :to="`${platform.domain}/docs/gdpr.pdf`" target="_blank" basic-link tabindex="-1" @click.stop>
            {{ $t('components.form.personalDataProcessing') }}
          </NuxtLink>.
        </FCheckboxVee>
      </div>
      <FButton name="signup-register" :disabled="!meta.dirty || !meta.valid" variant="primary" size="lg" w-full mt-48px :loading="isSubmitting" type="submit">
        {{ $t('components.form.createNewAccount') }}
      </FButton>
    </form>

    <div mt-32px paragraph-md text-center>
      {{ $t('components.form.alreadyHaveAccount') }}
      <button type="button" golden-link @click="emit('changeView', 'login')">
        {{ $t('components.form.login') }}
      </button>.
    </div>
  </div>
</template>
